/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Esta mañana estaba desmontando un viejo video y me he acordado de una página muy buena de alguien que igual que a mi le gusta desguazar aparatos para reciclar sus componentes."), "\n", React.createElement(_components.p, null, "Aqui la teneis ", React.createElement(_components.a, {
    href: "http://www.xbot.es/heli/"
  }, "Reciclaje electronico"), ", la dejo en mi blogroll para tenerla siempre a mano."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
